import { ConfigProvider } from "antd";
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import config from "./config.json";
import Customer from './pages/customer/Customer.jsx';
import Customers from './pages/customer/Index';
import Debit from './pages/debit/Index.jsx';
import Home from './pages/home';
import Initial from './pages/initial';
import Layout from './pages/layout';
import Login from './pages/login';
import Movements from './pages/movements/index';
import AccountingMovement from './pages/accountingMovement';
import NotFound from './pages/notFound';
import Payment from './pages/payment/Index'
import ProtectedRoute from './components/common/protectedRoute';
import SatReport from './pages/satReport';
import Services from './pages/services';
import Contracts from './pages/services/contracts';
import StockMaterials from './pages/stockMaterials';
import Templates from './pages/templates/index';
import UnAuthorized from './pages/unAuthorized';
import Category from './pages/category';
import Products from './pages/products';
import Plan from './pages/plan/Index';
import OpenCashRegister from "./pages/cashReconciliation/OpenCashRegister.jsx";
import CashWithdrawal from './pages/cashReconciliation/CashWithdrawal.jsx';
import CashCount from './pages/cashReconciliation/CashCount.jsx';
import CashReport from './pages/cashReconciliation/CashReport.jsx';
import Dashboard from "./pages/dashBoard";
import { UserContextProvider } from './context/userContext';

function App() {
	return (
		<UserContextProvider>
			<ConfigProvider {...config.antd}>
				<Routes>
					<Route
						element={
							<ProtectedRoute>
								<Layout />
							</ProtectedRoute>
						}>
						<Route path='/intelinet' element={<Home />} />
						<Route path='/intelinet/clientes' element={<ProtectedRoute roles="CLIENT_READ"><Customers /></ProtectedRoute>} />
						<Route path='/intelinet/clientes/:id' element={<ProtectedRoute roles="CLIENT_UPDATE"><Customer /></ProtectedRoute>} />
						<Route path='/intelinet/pagos' element={<ProtectedRoute roles="PAYMENT_READ"><Payment /></ProtectedRoute>} />
						<Route path='/intelinet/planes' element={<ProtectedRoute roles="PLAN_READ"><Plan /></ProtectedRoute>} />
						<Route path='/intelinet/reporte-adeudos' element={<Debit />} />
						<Route path='/intelinet/categorias' element={<ProtectedRoute roles="CATEGORY_READ"><Category /></ProtectedRoute>} />
						<Route path='/intelinet/productos' element={<ProtectedRoute roles="PRODUCT_READ"><Products /></ProtectedRoute>} />
						<Route
							path='/intelinet/reporte-movimientos'
							element={<AccountingMovement />}
						/>
						<Route path='/intelinet/movimientos' element={<Movements />} />
						<Route path='/intelinet/reporte-corte' element={<SatReport />} />
						<Route path='/intelinet/inventario' element={<StockMaterials />} />
						<Route path='/intelinet/plantillas' element={<Templates />} />
						<Route path='*' element={<NotFound />} />
						<Route path='/intelinet/servicios' element={<ProtectedRoute roles="SERVICE_READ"><Services /></ProtectedRoute>} />
						<Route path='/intelinet/servicios/contrato/:contractId' element={<Contracts />} />
						<Route path='/intelinet/servicios/contrato' element={<Contracts />} />
						<Route path='/intelinet/caja/apertura' element={<ProtectedRoute roles="CASH_RECON_OPEN"><OpenCashRegister /></ProtectedRoute>} />
						<Route path='/intelinet/caja/retiro' element={<ProtectedRoute roles="CASH_RECON_WITHDRAW"><CashWithdrawal /></ProtectedRoute>} />
						<Route path='/intelinet/caja/arqueo' element={<ProtectedRoute roles="CASH_RECON_CLOSE"><CashCount /></ProtectedRoute>} />
						<Route path='/intelinet/caja/reporte' element={<ProtectedRoute roles="CASH_RECON_REPORT"><CashReport /></ProtectedRoute>} />
						<Route path='/intelinet/dashboard' element={<ProtectedRoute roles={["DASHBOARD_AMOUNT_MONTH", "DASHBOARD_CLIENTS_MONTH"]}><Dashboard /></ProtectedRoute>} />
						<Route path='/unauthorized' element={<UnAuthorized />} />
					</Route>
					<Route path='/' element={<Initial />} />
					<Route path='/intelinet/login' element={<Login />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</ConfigProvider>
		</UserContextProvider>
	);
}

export default App;
