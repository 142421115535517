import http from './httpService';

const endpoint = 'AccountingMovement';

export function getCustomersAmount() {
    return http.get(`${endpoint}/GetItemsGroupedTotalCustomers`);
}

export function getMonthlyAmount() {
    return http.get(`${endpoint}/GetOpenItemsGroupedTotalAmount`);
}

export default {
    getCustomersAmount,
    getMonthlyAmount,
}