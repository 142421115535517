import React, { useMemo, useState } from 'react';
import { Button, notification, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { useChartData } from '../../hooks/useChartData';
import { getCustomersAmount } from '../../services/dashboardService';
import { getRandomColor } from '../../utils/randomColor';
import { dashboardFormatter } from '../../utils/dashboardFormatter';

const transformData = (data) => {
    const groupedData = {};

    data.forEach(({ periodYear, periodMonth, salesOfficeId, salesOfficeName, settledClients, debtorClients }) => {
        const key = `${periodMonth}-${periodYear}`;

        // To initialize the key
        if (!groupedData[key]) {
            groupedData[key] = { key };
        }

        const trimmedName = salesOfficeName.replace(/\bOficina\b/g, '').trim();
        const officeKey = `Oficina ${salesOfficeId} ${trimmedName}`;
        groupedData[key][officeKey + ' Pagaron'] = settledClients;
        groupedData[key][officeKey + ' Deben'] = debtorClients;
        
    });
    
    return Object.values(groupedData);
}

// To generate the Bars and Legend colors
const dynamicColors = () => {
    const colors = [];
    
    colors.push(getRandomColor(50, 100)); // settledClients color
    colors.push(getRandomColor(50, 100)); // debtorClients  color
    
    return colors;
}

const chartTitle = 'Conteo clientes mensual';

const ClientsMonthChart = ({ mobileMediaQuery, dynamicWidth }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

    const { error,
            isLoading,
            officeNames,
            chartData,
            fetchChartData } = useChartData(transformData, getCustomersAmount);

    const officeColors = useMemo(() => dynamicColors(officeNames), [chartData]);

    mobileMediaQuery.addEventListener('change', (event) => { 
        setIsMobile(event.matches);
    });


    return (
        <Spin spinning={isLoading} >
            <h2>
                {chartTitle}
                <Button type="primary" shape="circle" icon={<ReloadOutlined />} 
                        onClick={() => fetchChartData()} style={{ marginLeft: 8 }}
                        loading={isLoading}
                />
            </h2>

            { error && notification.warning({ message: chartTitle, description: error })}

            {/* To prevent shape-shifting */}
            { isLoading &&
                <div style={{ width: '100%', height: '400px' }}></div>
            }

            { !isLoading && 
                <div className='chart-overflow'>
                    <BarChart data={chartData} width={dynamicWidth(officeNames, chartData, isMobile ? 30 : 50)} height={400}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='key' />
                        <YAxis tickFormatter={(value) => dashboardFormatter(value, 1, '')} />
                        <Tooltip content={<CustomTooltip />} />
                        {
                            officeNames?.map((element) =>
                                <Bar 
                                    key={element} 
                                    dataKey={element}
                                    stackId={element.split(' ')[1]}
                                    fill={element.includes('Pagaron') ? officeColors[0] : officeColors[1]}
                                >
                                    <LabelList dataKey={element} fill='#000' />
                                </Bar>
                            )
                        }
                    </BarChart>
                </div>
            }


            {/* Custom Legend */}
            {!isLoading && (
                <ul className={isMobile ? 'chart-custom-legend-mobile' : 'chart-custom-legend-desktop'}>
                    <li style={{ color: officeColors[1], display: 'flex', alignItems: 'center' }}>
                        <div className='chart-bar-legend-square' style={{ backgroundColor: officeColors[1] }}></div> Clientes que deben
                    </li>
                    <li style={{ color: officeColors[0], display: 'flex', alignItems: 'center' }}>
                        <div className='chart-bar-legend-square' style={{ backgroundColor: officeColors[0] }}></div> Clientes que pagaron
                    </li>
                </ul>
            )}
        </Spin>
  )
}

const CustomTooltip = ({active, payload, label}) => {
    if (active) {
        return (
            <div className='chart-custom-tooltip'>
                <span>{label}</span>
                <div>
                    <span style={{ opacity: '80%', fontSize: '0.8rem'}}>
                        Pagaron - Deben
                    </span>
                </div>
                <hr style={{ opacity: '70%'}}/>
                
                <div className='chart-custom-tooltip-content'>
                    {
                        
                        payload.map(item => {
                            const trimmedName = item.dataKey.replace(/\bPagaron\b/g, '').trim();
                            const isSettled = item.dataKey.includes('Pagaron');
                            const isDebtor = item.dataKey.includes('Deben');
                            
                            return (
                                <React.Fragment key={item.dataKey}>
                                    { isSettled && (
                                        <>
                                            <span>{trimmedName}: </span>
                                            <span style={{ color: item.fill }}>{item.value}</span>
                                        </>
                                    )}
                                    { isDebtor && (
                                        <>
                                            <span style={{ color: item.fill }}>{item.value}</span>
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    return null;
}

export default ClientsMonthChart;