import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, notification, Popconfirm, Row, Skeleton, Space, Spin } from 'antd';
import { FaBuilding, FaPencilAlt } from 'react-icons/fa';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import cashReconciliationService from '../../services/cashReconciliationService';

import SalesOfficeModal from '../../components/modalSalesOffice';
import NumberItem from '../../components/common/characteristics/items/NumberItem';
import { currencyFormat } from '../../utils/currencyFormat';

const CashWithdrawal = () => {
    const [form] = Form.useForm();
    const [modalSalesOfficeVisible, setModalSalesOfficeVisible] = useState(false);
    const [dataSalesOffice, setDataSalesOffice] = useState([]);
    const [salesOffice, setSalesOffice] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [openPopconfirm, setOpenPopconfirm] = useState(false);
    const [btnEditOfficeVisible, setBtnEditOfficeVisible] = useState(false);

    // To check every change that is made to the inputs
    const amount = Form.useWatch('monto', form);
    const reason = Form.useWatch('conceptoDeRetiro', form);
    
    const getCashRegisterStatus = async (id) => {
        setIsLoadingStatus(true);

        try {
            const { data } = await cashReconciliationService.getCurrentCashRegister(id);
            // To check if the cash register is open
            setIsActive(data.active);
        } finally {
            setIsLoadingStatus(false);
        }
    }

    const handleModalOkSalesOffice = (office) => {
        // Prevents fields from being cleared if the same office has been selected
        if (office !== salesOffice) {
            setSalesOffice(office);
            form.resetFields();
        }
        getCashRegisterStatus(office.id);
        setIsLoading(false);
        setDisableButtons(false);
        setModalSalesOfficeVisible(false);
    }

    useEffect(() => {
        async function getSalesOffice() {
          setDisableButtons(true);
          const { data } = await getSalesOfficesMy();
          setDataSalesOffice(data);
          
          // If there is access to more than one office. The modal will be displayed
          if (data.length > 1) {
            setBtnEditOfficeVisible(true);
            setModalSalesOfficeVisible(true);
            return;
          }
          
          // If there is access to a single office. The modal won't be displayed
          if (data.length === 1) {
            setIsLoading(false);
            getCashRegisterStatus(data[0].id);
            setSalesOffice(data[0]);
            return;
          }
    
          // If there's no access to an office. The Alert will be displayed
          setIsLoading(false);
        }
        getSalesOffice();
    }, []);

    useEffect(() => {
        setOpenPopconfirm( !( (amount && reason) && amount > 0) );
    }, [amount, reason]);

    const handleConfirm = () => {
        form.validateFields()
            .then(async values => {
                try {
                    setLoadingWithdraw(true);
                    const { data } = await cashReconciliationService.withdrawCash(salesOffice.id, values.monto, values.conceptoDeRetiro); 
                    notification.success({ message: 'Retiro de caja', description: (<span>Retiro realizado con número: <strong>{ data.id }</strong></span>) });
                    form.resetFields();
                } catch (error) {
                    if (error.response && error.response.status === 400)
                        notification.warning({ message: 'Retiro de caja', description: error.response.data });
                } finally {
                    setLoadingWithdraw(false);
                }
            }).catch(() => { });
    }

    return (
        <>
            <SalesOfficeModal 
                visible={modalSalesOfficeVisible}
                salesOffice={dataSalesOffice} 
                onOk={handleModalOkSalesOffice} 
            />

            <div className='main-container'>
                <Row>
                    <Col span={24}>
                        <h1 className="title">Retiro de caja</h1>
                        <hr className="hr-title" />
                    </Col>
                </Row>

                <Spin spinning={isLoadingStatus}>
                    
                    <Row className='detail-container'>
                        <Skeleton  active loading={isLoading}>

                            {/* No permission to the sales offices */}
                            {!salesOffice && (
                                <Alert
                                    message='Sin permiso'
                                    description='Usted no tiene permisos para las oficinas de ventas.'
                                    type='info'
                                    style={{ width: '100%' }}
                                    showIcon
                                />
                            )}

                            {/* There's permission to the sales offices */}
                            {salesOffice && (
                                <Col span={24}>
                                    <h4 className="div-right color-p">
                                        <FaBuilding /> {salesOffice.name}
                                        <Button type="primary" style={{ marginLeft: '5px' }} className={'collapse' + (btnEditOfficeVisible ? 'in' : '')}
                                            shape="circle" disabled={disableButtons} icon={<FaPencilAlt />} onClick={() => setModalSalesOfficeVisible(true)} />
                                    </h4>
                                </Col>
                            )}

                            {/* The cash register is closed */}
                            {salesOffice && !isActive && (
                                <Alert
                                    message='Caja cerrada'
                                    description='No existe caja abierta para la oficina de ventas seleccionada.'
                                    type='info'
                                    style={{ width: '100%' }}
                                    showIcon
                                />
                            )}


                            {/* There's permission to the sales offices */}
                            {salesOffice && isActive && (
                                <Col span={24}>
                                    <Form layout='vertical' form={form}>
                                        <Space direction='vertical' style={{ width: '100%' }}>
                                            <NumberItem
                                                name='monto'
                                                label={'Monto de retiro'}
                                                prefix={'$'}
                                                width='100%'
                                                required={true}
                                                precision={2}
                                                text={''} // This is necessary for the validations to work correctly
                                                allowNegatives={false}
                                            />

                                            <Form.Item 
                                                label='Concepto'
                                                name='conceptoDeRetiro'
                                                style={{ marginTop: '-20px'}}
                                                required={true}
                                                rules={{
                                                    required: true,
                                                    message: '¡Ingresa el concepto del retiro!',
                                                }}
                                            >
                                                <Input.TextArea 
                                                    maxLength={128} 
                                                    autoSize={{ minRows: 2, maxRows: 3 }}
                                                    showCount
                                                    onChange={ (e) => {
                                                        // Don't allow spaces only
                                                        if (e.target.value.trim() === '')
                                                            form.setFieldValue('conceptoDeRetiro', '');
                                                    }}
                                                />
                                            </Form.Item>
                                            
                                            <Row gutter={16} justify='end'>
                                                <Form.Item>
                                                    <Button
                                                        type='ghost'
                                                        htmlType='button'
                                                        size='middle'
                                                        style={{marginRight: '10px'}}
                                                        disabled={disableButtons}
                                                        onClick={() => { form.resetFields() }}
                                                    >
                                                        Limpiar
                                                    </Button>

                                                    <Popconfirm
                                                        title={(<span>¿Confirmar retiro de caja en <strong>{salesOffice.name}</strong> con <strong>{ currencyFormat(amount) || '$0.00' }</strong>?</span>)}
                                                        okText='Continuar'
                                                        onConfirm={handleConfirm}
                                                        cancelText='Cancelar'
                                                        okButtonProps={{ loading: loadingWithdraw }}
                                                        placement='left'
                                                        disabled={openPopconfirm}
                                                    >
                                                        <Button
                                                            type='primary'
                                                            htmlType='button'
                                                            size='middle'
                                                            loading={loadingWithdraw}
                                                            disabled={disableButtons || openPopconfirm}
                                                        >
                                                            Retirar
                                                        </Button>
                                                    </Popconfirm>
                                                </Form.Item>
                                            </Row>
                                        </Space>
                                    </Form>
                                </Col>
                            )}
                        </Skeleton>
                    </Row>

                </Spin>

            </div>
        </>
    )
}

export default CashWithdrawal;