import {
	Table,
	Button,
	Row,
	Col,
	notification,
	Select,
	Checkbox,
	Tooltip,
	Modal,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
	IoMdDocument,
	IoMdCalendar,
	IoMdPrint,
	IoMdCloseCircleOutline,
} from 'react-icons/io';
import { getAcc } from '../../services/accountService';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import { getAccMov } from '../../services/accountingMovementService';
import useAccountingTypes from '../../hooks/useAccountingTypes';
import config from '../../config.json';
import Cancel from './cancel';
import ModalFecha from '../../components/modalFecha';
import Ticket from '../../components/ticket';

import '../../etiquetaPrint.css';

const { dateTimeFormat } = config;

export default function AccountingMovement() {
	const { Option } = Select;
	const [dataTableCompleta, setDataTableCompleta] = useState([]);
	const [dataSalesOffice, setDataSalesOffice] = useState([]);
	const [selLoading, setSelLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [accountLoading, setAccountLoading] = useState(false);
	const [dataAccount, setDataAccount] = useState([]);
	const [modalVisibleFecha, setModalVisibleFecha] = useState(false);
	const [selectedAccounts, setSelectedAccounts] = useState([]);
	const [selectedSalesOffice, setSelectedSalesOficce] = useState([]);
	const [selectedAccountingTypes, setSelectedAccountingTypes] = useState([]);
	const [selectedFechas, setSelectedFechas] = useState([]);
	const [btnExcelVisible, setBtnExcelVisible] = useState(false);
	const [docCompensados, setDocCompensados] = useState(false);
	const [docClientes, setDocClientes] = useState(false);
	const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
	const [accountingId, setAccountingId] = useState(null);
	const [selVisible, setSelVisible] = useState(false);
	const { isLoading: accTypeSelLoading, data: dataAccTypes } = useAccountingTypes();
	const headers = [
		{ label: 'ID', key: 'id' },
		{ label: 'Num. Mov.', key: 'movementType.id' },
		{ label: 'Movimiento', key: 'movementType.name' },
		{ label: 'Posición', key: 'accountPosition' },
		{ label: 'Cuenta', key: 'accountName' },
		{ label: 'Importe', key: 'accountingValue' },
		{ label: 'Detalles', key: 'accountDetailsText' },
		{ label: 'Indicador D/C', key: 'debitCreditIndicator' },
		{ label: 'Mes', key: 'month' },
		{ label: 'Año', key: 'year' },
		{ label: 'Fecha', key: 'date' },
		{ label: 'Usuario', key: 'user.userName' },
		{ label: 'N° Cliente', key: 'clientId' },
		{ label: 'Doc. Compensación', key: 'compensationId' },
		{ label: 'Doc. Anulación', key: 'cancellationId' },
		{ label: 'Tipo de documento', key: 'accountingTypeId' },
		{ label: 'Oficina de venta', key: 'salesOffice.name' },
		{ label: '# Ticket ', key: 'ticketNumber' },
	];

	useEffect(() => {
		async function accounts() {
			setAccountLoading(true);
			const request = {
				Accounts: [],
			};
			const { data } = await getAcc(request);
			setDataAccount(data);
			setAccountLoading(false);
		}

		async function salesOffice() {
			setSelLoading(true);
			const { data } = await getSalesOfficesMy();
			setDataSalesOffice(data);
			setSelLoading(false);
			if (data.length > 1)
				return setSelVisible(true);
			handleChangeSalesOffice([data[0].id])

		}

		accounts();
		salesOffice();
	}, []);

	function handleChangeAccounts(value) {
		setSelectedAccounts(value);
	}

	function handleChangeSalesOffice(value) {
		setSelectedSalesOficce(value);
	}
	
	function handleChangeAccountingType(value) {
		setSelectedAccountingTypes(value);
	}

	const handleClickModalFecha = () => {
		setModalVisibleFecha(true);
	};

	const handleCancelModalFecha = () => {
		setModalVisibleFecha(false);
	};

	const handleOkModalFecha = data => {
		const items = [];
		for (let i = 0; i < data.length; i++) {
			const element = data[i];

			const f = moment(element.low, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss');
			let f2 = null;

			if (element.high)
				f2 = moment(element.high, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss');

			items.push({
				key: items.length + 1,
				option: element.option,
				sign: element.sign,
				low: f,
				high: f2,
			});

		}
		setSelectedFechas(items);
		setModalVisibleFecha(false);
	};

	const onOkCancelAccounting = () => {
		setCancelModalIsOpen(false);
		setAccountingId(null);
	};

	const handleClickReport = async () => {
		if (selectedSalesOffice.length === 0)
			return notification.info({ message: 'Reporte', description: selVisible ? 'Selecciona oficina(s) de ventas.' : 'No tienes una oficina de ventas asignada.' });
		setDataTableCompleta([]);
		setBtnExcelVisible(false);
		const request = {
			Accounts: selectedAccounts,
			ClearedPostings: docCompensados,
			ClientPostings: docClientes,
			DateRange: selectedFechas,
			salesOffices: selectedSalesOffice,
			accountingTypes: selectedAccountingTypes
		};
		setTableLoading(true);
		async function getReport() {
			const { data } = await getAccMov(request);
			if (data.length === 0) {
				notification.info({ message: 'Reporte', description: 'No se encontraron datos con los filtros seleccionados.' });
				setTableLoading(false);
				return false;
			} else {
				setBtnExcelVisible(true);
			}

			const dataModel = [];
			let indx = 1;
			for (let x = 0; x < data.length; x++) {
				const header = data[x];
				if (header.accountingDetails.length > 0) {
					for (let y = 0; y < data[x].accountingDetails.length; y++) {
						const detail = data[x].accountingDetails[y];
						dataModel.push({
							key: indx,
							id: header.id,
							customer: header.customer,
							organization: header.organization,
							movementType: header.movement.movementType,
							user: header.user,
							date: header.date,
							accountPosition: detail.account.id,
							accountName: detail.account.name,
							accountValue: detail.account.value,
							accountDetailsText: detail.text,
							debitCreditIndicator: detail.debitCreditIndicator,
							accountingValue: detail.value,
							month: header.month,
							year: header.year,
							clientId: detail.clientId,
							compensationId: detail.compensationId,
							accountingTypeId: header.accountingTypeId,
							ticketNumber: header.ticketNumber,
							salesOffice: header.salesOffice
						});
						indx++;
					}
				}
			}
			const dataOrder = dataModel.sort(function (a, b) {
				return a.id - b.id;
			});

			setDataTableCompleta(dataOrder);
			setTableLoading(false);
		}
		getReport();
	};

	const formatNumber = number => {
		const imp = ((number * 100) / 100).toFixed(2);
		return imp;
	};

	const rowClassColor = (record, index) =>
		record.accountValue >= 0 ? 'rowGreen' : 'rowRed';

	const onChangeCompensados = e => {
		setDocCompensados(e.target.checked);
	};

	const onChangeClientes = e => {
		setDocClientes(e.target.checked);
	};

	const columnsComplete = [
		{
			title: 'ID',
			dataIndex: 'id',
			fixed: 'left',
			render: value => Number(value)
		},
		{
			title: 'Movimiento',
			dataIndex: 'movementType',
			render: value =>
				`${value.id ? value.id + '-' : ''} ${value.name ? value.name : ''}`,
		},
		{
			title: 'Posición',
			dataIndex: 'accountPosition',
			render: value => value,
		},
		{
			title: 'Cuenta',
			dataIndex: 'accountName',
			render: value => value,
		},
		{
			title: 'Importe',
			dataIndex: 'accountingValue',
			render: value => `$ ${formatNumber(value)}`,
		},
		{
			title: 'Detalles',
			dataIndex: 'accountDetailsText',
		},
		{
			title: 'Indicador D/C',
			dataIndex: 'debitCreditIndicator',
			render: value => value,
		},
		{
			title: 'Mes',
			dataIndex: 'month',
			render: value => value,
		},
		{
			title: 'Año',
			dataIndex: 'year',
			render: value => value,
		},
		{
			title: 'Fecha',
			dataIndex: 'date',
			render: date => moment(date).format(dateTimeFormat),
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: value => value.userName,
		},
		{
			title: 'N° Cliente',
			dataIndex: 'clientId',
			render: value => value,
		},
		{
			title: 'Doc. Compensación',
			dataIndex: 'compensationId',
			render: value => value,
		},
		{
			title: 'Doc. Anulación',
			dataIndex: 'cancellationId',
		},
		{
			title: 'Tipo de documento',
			dataIndex: 'accountingTypeId',
		},
		{
			title: 'Oficina de venta',
			dataIndex: 'salesOffice',
			render: value => value && value.name,
		},
		{
			title: '# Ticket',
			dataIndex: 'ticketNumber',
		},
		{
			title: 'Reimprimir',
			dataIndex: 'print',
			render: (_, row) => {
				const canPrint =
					row.accountingTypeId === 'PC' && row.ticketNumber !== null;

				if (canPrint)
					return (
						<Tooltip title='Reimprimir'>
							<Button
								onClick={() => {
									setAccountingId(row.id);
									notification.info({
										message: 'Generando ticket',
									});
									setTimeout(() => {
										window.print();
									}, 5000);
								}}
								icon={<IoMdPrint color='#237804' />}></Button>
						</Tooltip>
					);
			},
		},
		{
			title: 'Cancelar',
			dataIndex: 'cancel',
			render: (_, row) => {
				const canCancel =
					row.accountingTypeId === 'PC' && row.ticketNumber !== null;
				if (canCancel)
					return (
						<Tooltip title='Cancelar'>
							<Button
								onClick={() => {
									setAccountingId(row.id);
									setCancelModalIsOpen(true);
								}}
								icon={<IoMdCloseCircleOutline color='#a8071a' />}></Button>
						</Tooltip>
					);
			},
		},
	];

	return (
		<>
			<div className='noImprimible'>
				<ModalFecha
					modalVisibleFecha={modalVisibleFecha}
					handleCancelModalFecha={handleCancelModalFecha}
					handleOkModalFecha={handleOkModalFecha}
				/>

				<Modal
					title='Cancelar movimiento contable'
					open={cancelModalIsOpen}
					onCancel={() => setCancelModalIsOpen(false)}
					width={480}
					footer={[
						<Button
							key='btnCancel'
							type='primary'
							onClick={() => {
								setCancelModalIsOpen(false);
							}}>
							Cerrar
						</Button>,
					]}>
					<Cancel
						accountingId={accountingId}
						onOkCancelAccounting={onOkCancelAccounting}
					/>
				</Modal>

				<Row>
					<Col span={24}>
						<h1 className='title'>Reporte de movimientos contables</h1>
						<hr className='hr-title' />
					</Col>
				</Row>

				<Row className='detail-container'>
					<Col span={20}></Col>
					<Col span={4}>
						<div className=''>
							<Button
								block
								type='primary'
								icon={<IoMdDocument />}
								onClick={handleClickReport}>
								&nbsp; Generar
							</Button>
						</div>
					</Col>
					<Col span={8} className={'mrg-2'}>
						<Select
							className={'mrg-2 collapse' + (selVisible ? 'in' : '')}
							mode='multiple'
							loading={selLoading}
							style={{ width: '100%' }}
							onChange={handleChangeSalesOffice}
							defaultValue={Object.keys(dataSalesOffice)[0]}
							placeholder='Oficina de ventas...'
							maxTagCount='responsive'>
							{dataSalesOffice.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={1}></Col>
					<Col span={8} className={'mrg-2'}>
						<Select
							className={'mrg-2'}
							mode='multiple'
							loading={accTypeSelLoading}
							style={{ width: '100%' }}
							onChange={handleChangeAccountingType}
							placeholder='Tipo de documento...'
							maxTagCount='responsive'>
							{dataAccTypes?.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.description}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={7}></Col>
					<Col span={8} className='mrg-2'>
						<div className=''>
							<Button
								block
								type='primary'
								icon={<IoMdCalendar />}
								onClick={handleClickModalFecha}>
								&nbsp; Fechas...
							</Button>
						</div>
					</Col>
					<Col span={1} />
					<Col span={8} className='mrg-2'>
						<Select
							mode='multiple'
							loading={accountLoading}
							style={{ width: '100%' }}
							onChange={handleChangeAccounts}
							placeholder='Cuentas...'
							maxTagCount='responsive'>
							{dataAccount.map(function (obj) {
								return (
									<Option key={obj.id} value={obj.id}>
										{obj.name}
									</Option>
								);
							})}
						</Select>
					</Col>
					<Col span={7} />
					<Col span={8} className='mrg-2'>
						<Checkbox onChange={onChangeCompensados}>Doc. Compensados</Checkbox>
					</Col>
					<Col span={1} />
					<Col span={8} className='mrg-2'>
						<Checkbox onChange={onChangeClientes}>Doc. Clientes</Checkbox>
					</Col>
					<Col span={12} />

					<Col span={24}>
						<Row className='mrg-2'>
							<Col
								span={6}
								className={'collapse' + (btnExcelVisible ? 'in' : '')}>
								<CSVLink
									data={dataTableCompleta}
									headers={headers}
									filename={'Reporte-Movimientos-Contables.csv'}
									className='ant-btn ant-btn-primary'>
									Descargar reporte
								</CSVLink>
							</Col>
							<Col span={24} className='mrg-2'>
								<Table
									rowKey='key'
									dataSource={dataTableCompleta}
									columns={columnsComplete}
									loading={tableLoading}
									scroll={{ x: 'auto' }}
									rowClassName={rowClassColor}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<div className='imprimible'>
				<Ticket
					accountingId={accountingId}
					efectivo={0}
					cambio={0}
				/>
			</div>
		</>
	);
}
