import http from './httpService';

const endPoint = "/AccountingMovement";

export function getAccMov(request) {
	return http.post(endPoint, request);
}

export function addNotaCargo(request) {
	return http.post(`${endPoint}/Add`, request);
}


export default { getAccMov, addNotaCargo };
