import React, { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { Button, notification, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useChartData } from '../../hooks/useChartData';
import { getMonthlyAmount } from '../../services/dashboardService';
import { numberFormat } from '../../utils/numberFormat';
import { dashboardFormatter } from '../../utils/dashboardFormatter';
import { getRandomColor } from '../../utils/randomColor';

const transformData = (data) => {
    const groupedData = {};

    data.forEach(({ periodYear, periodMonth, salesOfficeId, salesOfficeName, debitAmount }) => {
        const key = `${periodMonth}-${periodYear}`;

        // To initialize the key
        if (!groupedData[key]) {
            groupedData[key] = { key };
        }

        const trimmedName = salesOfficeName.replace(/\bOficina\b/g, '').trim();
        const officeKey = `Oficina ${salesOfficeId} ${trimmedName}`;
        groupedData[key][officeKey] = (groupedData[key][officeKey] || 0) + debitAmount;
        
    });
    
    return Object.values(groupedData);
}

// To generate the Bars and Legend colors
const dynamicColors = (offices) => {
    const colors = [];

    for (let index = 0; index < offices?.length; index++) {
        const color = getRandomColor(50, 100);
        colors.push(color);
    }

    return colors;
}

const chartTitle = 'Adeudos mensuales/Oficina';

const AmountMonthChart = ({ mobileMediaQuery, dynamicWidth }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

     const { error,
             isLoading,
             officeNames,
             chartData,
             fetchChartData } = useChartData(transformData, getMonthlyAmount);

    const officeColors = useMemo(() => dynamicColors(officeNames), [chartData]);

    mobileMediaQuery.addEventListener('change', (event) => { 
        setIsMobile(event.matches);
    });

    return (
        <Spin spinning={isLoading} >
            <h2>
                {chartTitle}
                <Button type="primary" shape="circle" icon={<ReloadOutlined />} 
                        onClick={() => fetchChartData()} style={{ marginLeft: 8 }}
                        loading={isLoading}
                />
            </h2>

            { error && notification.warning({ message: chartTitle, description: error })}

            {/* To prevent shape-shifting */}
            { isLoading &&
                <div style={{ width: '100%', height: '400px' }}></div>
            }

            { !isLoading && 
                <div className='chart-overflow'>
                    <BarChart data={chartData} width={dynamicWidth(officeNames, chartData)} height={400}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='key' />
                        <YAxis tickFormatter={(value) => dashboardFormatter(value, 1, '$')} />
                        <Tooltip formatter={(value) => numberFormat(value)} />
                        {
                            officeNames?.map((element, index) => 
                                <Bar key={element} dataKey={element} fill={officeColors[index]} />
                            )
                        }
                    </BarChart>
                </div>
            }

            {/* Custom Legend */}
            {!isLoading && (
                <ul className={isMobile ? 'chart-custom-legend-mobile' : 'chart-custom-legend-desktop'}>
                    {officeNames?.map((element, index) => (
                        <li key={element} style={{ color: officeColors[index], display: 'flex', alignItems: 'center' }}>
                            <div className='chart-bar-legend-square' style={{ backgroundColor: officeColors[index] }}></div> {element}
                        </li>
                    ))}
                </ul>
            )}
        </Spin>
    )
}

export default AmountMonthChart;