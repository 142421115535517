import { useEffect, useState } from 'react';

export const useChartData = ( transformData, service ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState();
    const [officeNames, setOfficeNames] = useState();
    const [error, setError] = useState();

    // Obtain office names to dynamically create Chart Bars
    const getOfficeNames = (data) => {
        const offices = [
            ...new Set( // Set to store unique values
                data.flatMap(Object.keys) // Get the objects keys
                    .filter(key => key !== 'key') // Remove 'key'
            )
        ];

        // Sort by office number
        return offices.sort((a, b) => a.split(' ')[1] - b.split(' ')[1]);
    }

    const getChartData = async () => {
        try {
            setIsLoading(true);
            const { data } = await service();
            const transformedData = transformData(data);
            
            const offices = getOfficeNames(transformedData);
            
            return { transformedData, offices };
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const fetchChartData = async () => {
        const {transformedData, offices} = await getChartData();
        setChartData(transformedData);
        setOfficeNames(offices);
    }

    useEffect(() => {
        fetchChartData();
    }, []);

    return {
        error,
        isLoading,
        chartData,
        officeNames,
        fetchChartData
    }

}