import React from 'react';
import { Col, Row } from 'antd';
import authService from '../../services/authService';
import AmountMonthChart from './AmountMonthChart';
import ClientsMonthChart from './ClientsMonthChart';

// If the screen is equal or smaller than 700px means Mobile View is being used
const mobileMediaQuery = window.matchMedia('(max-width: 700px)');

// To extend the size of the chart container dynamically
const dynamicWidth = (offices, data, basis = 50) => {
    const chartQuadrantWidth = offices?.length * basis;
    return data?.length * chartQuadrantWidth;
}

export default function DashboardPage() {
    return (
        <>
            <div className='main-container'>
                <Row>
                    <Col span={24}>
                        <h1 className='title'>Dashboard</h1>
                        <hr className='hr-title' />
                    </Col>
                </Row>

                <div className='detail-container-without-overflow'>

                    { authService.isInRole('DASHBOARD_AMOUNT_MONTH') && (
                        <div style={{ marginBottom: '3rem' }}>
                            <AmountMonthChart 
                                mobileMediaQuery={mobileMediaQuery} 
                                dynamicWidth={dynamicWidth}
                            />
                        </div>
                    )}

                    { authService.isInRole('DASHBOARD_CLIENTS_MONTH') && (
                        <div>
                            <ClientsMonthChart 
                                mobileMediaQuery={mobileMediaQuery} 
                                dynamicWidth={dynamicWidth}
                            />
                        </div>
                    )}

                </div>
            </div>

        </>
    )
}
