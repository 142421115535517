import React, { useState } from 'react';
import config from '../../config.json';
import auth from "../../services/authService";
import { approveInstallation, installContract } from '../../services/contractService';
import { Button, Col, Form, notification, Row } from 'antd';
import { CheckSquareOutlined, CloseCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { RejectInstallationModal } from './RejectInstallationModal';



export const InstallationFlowButtons = ({contractId, contractStatusId, refreshContract}) => {
    const [loadingStatusChange, setLoadingStatusChange] = useState(false);
    const [openRejectInstallationModal, setOpenRejectInstallationModal] = useState(false);
    
    const onOkRejectInstallation = () => {
		setOpenRejectInstallationModal(false);
		refreshContract();
	}

    const handleInstallContract = async (contractId) => {
        try {
            setLoadingStatusChange(true);
            await installContract(contractId, { comments: null });
            refreshContract();
            notification.success({ message: 'Contrato', description: 'El contrato ha sido instalado satisfactoriamente.' });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                notification.warning({ message: 'Instalar', description: error.response.data });
            }
        } finally {
            setLoadingStatusChange(false);
        }
    }

    const handleApproveInstallation = async (contractId) => {
        try {
            setLoadingStatusChange(true);
            await approveInstallation(contractId, { comments: null });
            refreshContract();
            notification.success({ message: 'Contrato', description: 'El contrato ha sido aprobado satisfactoriamente.' });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                notification.warning({ message: 'Aprobar', description: error.response.data });
            }
        } finally {
            setLoadingStatusChange(false);
        }
    }

    return (
        <>
            <RejectInstallationModal
                contractId={contractId}
                open={openRejectInstallationModal}
                onOkRejectInstallation={onOkRejectInstallation}
                onCancel={() => setOpenRejectInstallationModal(false)}
            />

            <Col span={24}>
                <Row gutter={16} justify='end'>
                    {[config.statusContract.solicitado, config.statusContract.instalacionRechazada].some(s => s === contractStatusId) && (auth.isInRole('SERVICE_FILTER_SOL') || auth.isInRole('SERVICE_FILTER_IRE')) && (
                        <Col>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    size='large'
                                    loading={loadingStatusChange}
                                    onClick={() => handleInstallContract(contractId)}
                                    icon={<VerticalAlignBottomOutlined />}>
                                    Instalar
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                    {contractStatusId === config.statusContract.instalado && auth.isInRole('SERVICE_FILTER_INS') && (
                        <Col>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    size='large'
                                    loading={loadingStatusChange}
                                    onClick={() => setOpenRejectInstallationModal(true)}
                                    icon={<CloseCircleOutlined />}>
                                    Rechazar
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                    {contractStatusId === config.statusContract.instalado && auth.isInRole('SERVICE_FILTER_INS') && (
                        <Col>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    size='large'
                                    loading={loadingStatusChange}
                                    onClick={() => handleApproveInstallation(contractId)}
                                    icon={<CheckSquareOutlined />}>
                                    Aprobar
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Col>
        </>
    )
}
